.offer-details__img-wrap {
  margin-bottom: 30px;
}
.table {

  &.table--bordered {

    tr.custom-no-border {
      border-bottom: none !important;
      border-top: none !important;
    }
    tr.custom, tr.custom-no-border {
      td:first-child {
        padding-left: 0;
        max-width: 300px;
        width: 250px;
      }
    }
  }
}

.trackback-url {
  margin-left: 10px;
}
.trackback-url-container {
  display: flex;
  align-items: center;
}

.creatives-panel__body {
  background-color: transparent !important;
}

.offer-details__creatives-row-resize {
  width: 100%;
}

.offer-details__field-container {
  width: 100%
}

.customize-icon-container {
  background-color: transparent !important;
}

.customize-icon:hover {
  fill: #4ce1b6;
}
