.button-container {
  margin-top: 2px;
  width: 145px;
  & > div {
    height: 42px;
    line-height: 20px;
  }
}

.title-field-container {
  margin: 2px 0 0 0 !important;
  width: 100% !important;
  padding: 0;
  height: 42px;
  min-height: 42px;
  & > input {
    height: 42px;
  }
}
